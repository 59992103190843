export default [
  {
    name: "Monitor",
    dataTarget: "Monitor section",
    translationKey: "common.monitor",
    icon: ["fal", "fa-user-chart"],
    isPrefix: true,
    isVcdbOnly: false,
    subRoutes: [
      // {
      //   id: 1,
      //   translationKey: "common.mentions",
      //   path: "/pro/#/monitor?graph=articles",
      //   isExternalRoute: true,
      //   display: false,
      // },
      {
        id: 1,
        translationKey: "navbar.monitor.search",
        path: "/search",
        isExternalRoute: false,
        display: false,
        dataTarget: "Search Page",
      },
      {
        id: 17,
        translationKey: "navbar.manage.manageSearch",
        path: "/manage",
        isExternalRoute: true,
        display: true,
        dataTarget: "Manage Searches page",
      },
      {
        id: 2,
        translationKey: "common.mentions",
        path: "/mentions",
        isExternalRoute: false,
        display: false,
        dataTarget: "Mentions page",
      },
      {
        id: 3,
        translationKey: "common.topics",
        path: "/topics",
        isExternalRoute: false,
        display: false,
        dataTarget: "Topics page",
      },
      {
        id: 4,
        translationKey: "navbar.monitor.chartAnalytics",
        path: "/charts",
        isExternalRoute: false,
        display: false,
        dataTarget: "Chart Analytics page",
      },
      {
        id: 5,
        translationKey: "navbar.monitor.authorsAndPublications",
        path: "/pro/#/monitor/authors/details/authors",
        isExternalRoute: true,
        display: false,
      },
    ],
  },
  {
    name: "Reports",
    dataTarget: "Reports section",
    translationKey: "common.reports",
    icon: ["fal", "fa-file-chart-line"],
    isPrefix: true,
    isVcdbOnly: false,
    subRoutes: [
      {
        id: 7,
        translationKey: "common.reports",
        path: "/reports#/list",
        isExternalRoute: true,
        display: true,
        dataTarget: "Reports Page",
      },
      {
        id: 19,
        translationKey: "common.templates",
        path: "/reports#/templates",
        isExternalRoute: true,
        display: true,
        dataTarget: "Templates page",
      },
    ],
  },
  {
    name: "Contacts", // has vcdb
    dataTarget: "Contacts section",
    translationKey: "common.contacts",
    icon: ["fal", "fa-book-user"],
    isPrefix: true,
    isVcdbOnly: true,
    subRoutes: [
      {
        id: 8,
        translationKey: "navbar.contacts.searchContacts",
        path: "/pro/#/influencers/search",
        dataTarget: "Search Contacts page",
        isExternalRoute: true,
        display: false,
      },
      {
        id: 9,
        translationKey: "navbar.contacts.myContacts",
        path: "/pro/#/influencers/lists/contacts",
        dataTarget: "My Contacts page",
        isExternalRoute: true,
        display: false,
      },
      {
        id: 10,
        translationKey: "navbar.contacts.pitchLists",
        path: "/pro/#/influencers/lists/pitch-lists",
        dataTarget: "Pitch Lists page",
        isExternalRoute: true,
        display: false,
      },
      {
        id: 11,
        translationKey: "navbar.contacts.emailCampaigns",
        path: "/pro/#/influencers/email-campaigns",
        dataTarget: "Email Campaings page",
        isExternalRoute: true,
        display: false,
      },
    ],
  },
  {
    name: "Keyhole",
    dataTarget: "",
    translationKey: "sidebar.keyhole",
    icon: ["far", "key-skeleton"],
    isPrefix: true,
    subRoutes: [
      {
        id: 18,
        translationKey: "sidebar.keyhole",
        path: "/pro/#/manage/social-trackers",
        isExternalRoute: true,
        display: true,
      },
    ],
  },
  {
    name: "Manage",
    dataTarget: "Manage Section",
    translationKey: "common.manage",
    icon: ["fal", "fa-sliders"],
    isPrefix: true,
    isVcdbOnly: false,
    subRoutes: [
      {
        id: 12,
        translationKey: "navbar.manage.targetMediaLists",
        path: "/target-media-lists",
        isExternalRoute: false,
        dataTarget: "TML page",
        display: false,
      },
      {
        id: 14,
        translationKey: "common.alerts",
        path: "/alerts/real-time",
        isExternalRoute: true,
        dataTarget: "Alerts page",
        display: false,
      },
      {
        id: 15,
        translationKey: "navbar.manage.mentionsAndPublications",
        path: "/manage/added-mentions",
        isExternalRoute: false,
        dataTarget: "Mentions & Publications page",
        display: true,
      },
    ],
  },
  {
    name: "Settings",
    dataTarget: "Settings Section",
    translationKey: "sidebar.settings",
    icon: ["fal", "cog"],
    isPrefix: false,
    subRoutes: [
      {
        id: 16,
        translationKey: "navbar.monitor.settings",
        path: "/pro/#/settings",
        isExternalRoute: true,
        display: true,
      },
    ],
  },
  {
    name: "Help",
    dataTarget: "Help Button",
    translationKey: "sidebar.help",
    icon: ["fal", "circle-question"],
    isPrefix: false,
    subRoutes: [],
  },
  {
    name: "ExitUser",
    dataTarget: "",
    translationKey: "sidebar.logout",
    icon: ["fal", "fa-sign-out"],
    isPrefix: false,
    subRoutes: [],
  },
];
