import { default as _91id_93p8NV4QueL5Meta } from "/unified-client/pages/alerts/[id].vue?macro=true";
import { default as indexvC1vs3dEkKMeta } from "/unified-client/pages/charts/index.vue?macro=true";
import { default as indexi4DZmJzQ8aMeta } from "/unified-client/pages/expired/index.vue?macro=true";
import { default as indexXPUch3jAo5Meta } from "/unified-client/pages/explore/index.vue?macro=true";
import { default as indexO72oYXe3IKMeta } from "/unified-client/pages/fetch-article-alert/index.vue?macro=true";
import { default as indexp2Nmukip8hMeta } from "/unified-client/pages/fetch-email-digest/index.vue?macro=true";
import { default as indexog2LuypSk0Meta } from "/unified-client/pages/index.vue?macro=true";
import { default as indexaZkpptIckMMeta } from "/unified-client/pages/logIn/index.vue?macro=true";
import { default as indexlO2RrASVxIMeta } from "/unified-client/pages/logout/index.vue?macro=true";
import { default as _91id_93Qyw07xEZYPMeta } from "/unified-client/pages/manage/[id].vue?macro=true";
import { default as indexEPnpU7h6qiMeta } from "/unified-client/pages/manage/index.vue?macro=true";
import { default as indexz84hvswehoMeta } from "/unified-client/pages/mentions/index.vue?macro=true";
import { default as indexMrD89eK7xZMeta } from "/unified-client/pages/password/edit/index.vue?macro=true";
import { default as indexhGARqBLcCgMeta } from "/unified-client/pages/ppt-reports/index.vue?macro=true";
import { default as indexx2DgItUtELMeta } from "/unified-client/pages/pro/index.vue?macro=true";
import { default as indexB3lDp1RpV9Meta } from "/unified-client/pages/reports/index.vue?macro=true";
import { default as indexUVDchSUB2MMeta } from "/unified-client/pages/search/index.vue?macro=true";
import { default as indexTXGRLEKTpoMeta } from "/unified-client/pages/target-media-lists/index.vue?macro=true";
import { default as indexr6yZRyD8LnMeta } from "/unified-client/pages/topics/index.vue?macro=true";
export default [
  {
    name: "alerts-id",
    path: "/alerts/:id()",
    component: () => import("/unified-client/pages/alerts/[id].vue")
  },
  {
    name: "charts",
    path: "/charts",
    component: () => import("/unified-client/pages/charts/index.vue")
  },
  {
    name: "expired",
    path: "/expired",
    component: () => import("/unified-client/pages/expired/index.vue")
  },
  {
    name: "explore",
    path: "/explore",
    component: () => import("/unified-client/pages/explore/index.vue")
  },
  {
    name: "fetch-article-alert",
    path: "/fetch-article-alert",
    component: () => import("/unified-client/pages/fetch-article-alert/index.vue")
  },
  {
    name: "fetch-email-digest",
    path: "/fetch-email-digest",
    component: () => import("/unified-client/pages/fetch-email-digest/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/unified-client/pages/index.vue")
  },
  {
    name: "logIn",
    path: "/logIn",
    component: () => import("/unified-client/pages/logIn/index.vue")
  },
  {
    name: "logout",
    path: "/logout",
    component: () => import("/unified-client/pages/logout/index.vue")
  },
  {
    name: "manage-id",
    path: "/manage/:id()",
    component: () => import("/unified-client/pages/manage/[id].vue")
  },
  {
    name: "manage",
    path: "/manage",
    component: () => import("/unified-client/pages/manage/index.vue")
  },
  {
    name: "mentions",
    path: "/mentions",
    component: () => import("/unified-client/pages/mentions/index.vue")
  },
  {
    name: "password-edit",
    path: "/password/edit",
    component: () => import("/unified-client/pages/password/edit/index.vue")
  },
  {
    name: "ppt-reports",
    path: "/ppt-reports",
    component: () => import("/unified-client/pages/ppt-reports/index.vue")
  },
  {
    name: "pro",
    path: "/pro",
    component: () => import("/unified-client/pages/pro/index.vue")
  },
  {
    name: "reports",
    path: "/reports",
    component: () => import("/unified-client/pages/reports/index.vue")
  },
  {
    name: "search",
    path: "/search",
    component: () => import("/unified-client/pages/search/index.vue")
  },
  {
    name: "target-media-lists",
    path: "/target-media-lists",
    component: () => import("/unified-client/pages/target-media-lists/index.vue")
  },
  {
    name: "topics",
    path: "/topics",
    component: () => import("/unified-client/pages/topics/index.vue")
  }
]