import { defineStore } from "pinia";
import { getIanaFromRailsZone } from "~/utils/time-zones";
import Org from "~/types/OrgType";
import { useMediaListStore } from "~/store/mediaList";
import locations from "~/constants/locations";

type State = {
  org: Org | null;
};

export const useOrgStore = defineStore("org", {
  state: (): State => ({
    org: null,
  }),
  getters: {
    getOrg(state) {
      return state.org;
    },
    getWordPlay(state) {
      return state.org?.attributes.plan_features.enableWordplay;
    },
    getTimeZone(state) {
      return getIanaFromRailsZone(state.org?.attributes.time_zone);
    },
    getTMLLimit(state) {
      return state.org?.attributes.plan_limits.tmlTotal;
    },
    getTMLDomainsLimit(state) {
      return state.org?.attributes.plan_limits.tmlDomains;
    },
    getAllowedCountries(state) {
      const licenseCountries = state.org?.attributes.license_countries;

      if (
        !licenseCountries ||
        (Array.isArray(licenseCountries) &&
          (licenseCountries.length === 0 || licenseCountries.includes("GLB")))
      ) {
        return locations.ALL_SORTED_DROPDOWN;
      } else {
        return locations.ALL_SORTED_DROPDOWN.filter((location) =>
          licenseCountries.includes(location.iso),
        );
      }
    },
    hasPrintMediaAccess(state) {
      return state.org?.attributes.plan_features.printMedia;
    },
    hasBroadcastMediaAccess(state) {
      return (
        state.org?.attributes.plan_features.broadcastMedia ||
        state.org?.attributes.plan_features.usaBroadcastMedia
      );
    },
    hasArticleSentiment(state) {
      return state.org?.attributes.plan_features.articleSentiment;
    },
    hasPDFAccess(state) {
      return state.org?.attributes.plan_features.pdfAccess;
    },
    isFrench(state) {
      return state.org?.attributes?.license_region === "FRA";
    },
    isTrialAccount(state) {
      return state.org?.attributes.trial_account;
    },
    hasSocialTracking(state) {
      if (state.org?.attributes)
        return state.org?.attributes.plan_features.socialTracking;
      return false;
    },
    hasExceededTMLLimit(state) {
      const mediaListStore = useMediaListStore();

      return state.org?.attributes.plan_limits.tmlTotal
        ? mediaListStore.mediaLists.length >=
            state.org?.attributes.plan_limits.tmlTotal
        : false;
    },
    hasUKLicense(state): boolean {
      if (state.org?.attributes) {
        const printLicenses = state.org?.attributes.print_licenses;
        if (Array.isArray(printLicenses)) {
          return !!printLicenses.filter((license) =>
            ["NLA", "NLI", "CLA"].includes(license),
          ).length;
        }
      }
      return false;
    },
    monthlyLimit(state): number {
      return state.org?.attributes.licensed_article_limit || 0;
    },
    monthlyUsage(state): number {
      return state.org?.attributes.licensed_article_usage || 0;
    },
    remainingCredits(): number {
      return this.monthlyLimit - this.monthlyUsage;
    },
    showUsageCreditsWarning(): boolean {
      return (this.monthlyUsage * 100) / this.monthlyLimit >= 75;
    },
    hasNoRemainingCredits(): boolean {
      return this.monthlyUsage >= this.monthlyLimit;
    },
    dailyLimit(): number {
      if (this.monthlyLimit > 0) {
        return Math.round(this.monthlyLimit / 30);
      }
      return this.monthlyLimit;
    },
    isFrenchOrg(state): boolean {
      return state.org?.attributes.license_region === "FRA";
    },
    isUkOrg(state): boolean {
      return state.org?.attributes.license_region === "GBR";
    },
  },
  actions: {
    setOrg(org: Org) {
      this.org = org;
    },
    increaseMonthlyUsage() {
      if (this?.org?.attributes?.licensed_article_usage) {
        this.org.attributes.licensed_article_usage++;
      }
    },
  },
});
