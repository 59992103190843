import payload_plugin_qLmFnukI99 from "/unified-client/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_4sVQNw7RlN from "/unified-client/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/unified-client/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/unified-client/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/unified-client/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/unified-client/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/unified-client/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/unified-client/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/unified-client/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/unified-client/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/unified-client/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/unified-client/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import intercom_tYaoKVt49Q from "/unified-client/plugins/intercom.ts";
import O360ClickOutide_client_bZD0tzbZYK from "/unified-client/plugins/O360ClickOutide.client.ts";
import auth_vT9JWWT9pN from "/unified-client/plugins/auth.ts";
import clickOutside_client_Zc9fkH3Rh6 from "/unified-client/plugins/clickOutside.client.ts";
import elementplus_w0Pf9Fe9a2 from "/unified-client/plugins/elementplus.ts";
import fontawesome_klhsrycjcK from "/unified-client/plugins/fontawesome.js";
import highcharts_client_MYKkaxDq22 from "/unified-client/plugins/highcharts.client.ts";
import i18n_VfGcjrvSkj from "/unified-client/plugins/i18n.ts";
import mitt_S0QU5gJPTl from "/unified-client/plugins/mitt.ts";
import vuecalendar_jZeYP0aABt from "/unified-client/plugins/vuecalendar.ts";
export default [
  payload_plugin_qLmFnukI99,
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  intercom_tYaoKVt49Q,
  O360ClickOutide_client_bZD0tzbZYK,
  auth_vT9JWWT9pN,
  clickOutside_client_Zc9fkH3Rh6,
  elementplus_w0Pf9Fe9a2,
  fontawesome_klhsrycjcK,
  highcharts_client_MYKkaxDq22,
  i18n_VfGcjrvSkj,
  mitt_S0QU5gJPTl,
  vuecalendar_jZeYP0aABt
]